import { gql } from '@apollo/client';

export const PHONE_VALIDATION = gql`
  query validatePhone($phone: String!) {
    validatePhone(phone: $phone) {
      valid
      message
    }
  }
`;

export type PhoneValidationResponse = {
  validatePhone: {
    valid: boolean;
    message: string;
  };
};
