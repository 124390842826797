import { gql } from '@apollo/client';

// Checks if content has more than 10 words,
// doesn't repeat words 5 times consecutively,
// and contains no profanity.
// Returns boolean.
export const VALIDATE_CONTENT = gql`
  query validateContent($content: String!) {
    validateContent(content: $content)
  }
`;
