import type { RumInitConfiguration } from '@datadog/browser-rum';
import getConfig from 'next/config';

interface IRuntimeConfig {
  ADDRESS_FINDER_API_KEY: string;
  CACHE_KEY_PREFIX: string;
  DATADOG_APPLICATION_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  DATADOG_SERVICE_NAME: string;
  DATADOG_VERSION: string;
  DEBUG: string;
  DOMAIN: 'oneflare' | 'wedding';
  ENVIRONMENT: string;
  GRAPHQL_API: string;
  GRAPHQL_API_WS: string;
  GRAPHQL_TOKEN: string;
  GTM_ID: string;
  INTERSTITIAL_URL: string;
  JOB_FORM_URL: string;
  NODE_ENV: string;
  WP_CONTENT_HUB_API: string;
  SITE_URL: string;
  SITE_DEFAULT_QUOTES: number;
  NEXT_PUBLIC_STATSIG_CLIENT_KEY: string;
  NEXT_PRIVATE_STATSIG_CLIENT_KEY: string;
  FEATURE_MANAGEMENT_ENVIRONMENT: string;
}

interface IServerRuntimeConfig extends IRuntimeConfig {
  PAGE_VALIDATOR_TOKEN: string;
}

type IPublicRuntimeConfig = Pick<
  IRuntimeConfig,
  | 'DATADOG_VERSION'
  | 'DATADOG_APPLICATION_ID'
  | 'DATADOG_CLIENT_TOKEN'
  | 'DATADOG_SERVICE_NAME'
  | 'DOMAIN'
  | 'ENVIRONMENT'
  | 'GRAPHQL_API_WS'
  | 'GRAPHQL_TOKEN'
  | 'JOB_FORM_URL'
  | 'SITE_URL'
  | 'GRAPHQL_API'
  | 'SITE_DEFAULT_QUOTES'
  | 'WP_CONTENT_HUB_API'
  | 'NEXT_PUBLIC_STATSIG_CLIENT_KEY'
  | 'FEATURE_MANAGEMENT_ENVIRONMENT'
> & {
  NEXT_PUBLIC_SEGMENT_CDN_URL: string;
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: string;
  CLOUDFRONT_IMAGE_RESIZE_URL: string;
  STRIPE_PUBLIC_KEY: string;
};

const publicRuntimeConfig = getConfig()
  .publicRuntimeConfig as IPublicRuntimeConfig;
const serverRuntimeConfig = getConfig()
  .serverRuntimeConfig as IServerRuntimeConfig;

const getWindowAppConfig = () => {
  if (typeof window === 'undefined') {
    return {
      dataDogConfigurations: {}
    } as Window['appConfig'];
  }
  return window.appConfig;
};

const getDomain = () => publicRuntimeConfig.DOMAIN;

const getGraphQlApi = () =>
  serverRuntimeConfig.GRAPHQL_API ?? publicRuntimeConfig.GRAPHQL_API;

const getGraphQlToken = () =>
  serverRuntimeConfig.GRAPHQL_TOKEN ?? publicRuntimeConfig.GRAPHQL_TOKEN;

const getSiteUrl = () =>
  serverRuntimeConfig.SITE_URL ?? publicRuntimeConfig.SITE_URL;

const getJobFormUrl = () => publicRuntimeConfig.JOB_FORM_URL;

const getWpContentHubApi = () =>
  serverRuntimeConfig.WP_CONTENT_HUB_API ??
  publicRuntimeConfig.WP_CONTENT_HUB_API;

const getDataDogConfig = (): RumInitConfiguration => {
  const { isBot, staticRender } = getWindowAppConfig();

  // #region datadog variables
  const {
    ENVIRONMENT,
    DATADOG_VERSION,
    DATADOG_SERVICE_NAME,
    DATADOG_CLIENT_TOKEN,
    DATADOG_APPLICATION_ID
  } = publicRuntimeConfig;

  const env = ENVIRONMENT === 'production' ? 'prod' : 'dev';
  const version = DATADOG_VERSION;
  const sampleRateEnabled = true;
  const sessionSampleRate = 30;
  const sessionReplaySampleRate = 0;
  const userIntractionEnabled = true;
  const trackUserInteractions = true;
  // #endregion datadog variables

  // if it's a bot or static render, we don't need to initialize datadog
  if (!isBot && !staticRender) {
    return {
      clientToken: DATADOG_CLIENT_TOKEN,
      applicationId: DATADOG_APPLICATION_ID,
      service: DATADOG_SERVICE_NAME,
      env,
      site: 'datadoghq.com',
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      // we have wedding domain hosted from this repo as well
      allowedTracingUrls: [
        /https?:\/\/(?!collector\.oneflare\.com\.au)([\dA-Za-z-]+\.)?(oneflare|wedding)\.com\.au/
      ],
      ...(version && { version }),
      ...(sampleRateEnabled && { sessionReplaySampleRate }),
      ...(sampleRateEnabled && { sessionSampleRate }),
      ...(userIntractionEnabled && { trackUserInteractions })
    };
  }
  return {} as RumInitConfiguration;
};

// used to ensure that the header is only added when building with CI
type BuildHeader = {
  'x-oneflare-ci': string;
};

const getBuildHeaders = (): BuildHeader => {
  if (
    process.env.IS_BUILDING == 'true' &&
    process.env.ONEFLARE_CI_BUILD_HEADER
  ) {
    return { 'x-oneflare-ci': process.env.ONEFLARE_CI_BUILD_HEADER };
  }
  return {} as BuildHeader;
};

const getFeatureManagementEnvironmentConfig = () => {
  return {
    environment: publicRuntimeConfig.FEATURE_MANAGEMENT_ENVIRONMENT,
    clientSideApiKey: publicRuntimeConfig.NEXT_PUBLIC_STATSIG_CLIENT_KEY
  };
};

export {
  serverRuntimeConfig,
  publicRuntimeConfig,
  getDomain,
  getGraphQlApi,
  getGraphQlToken,
  getBuildHeaders,
  getSiteUrl,
  getJobFormUrl,
  getDataDogConfig,
  getWpContentHubApi,
  getWindowAppConfig,
  getFeatureManagementEnvironmentConfig
};
