import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_VERIFICATION = gql`
  mutation createBusinessVerification(
    $businessDetailsParams: BusinessVerificationDetailParams,
    $userDetailsParams: UserVerificationDetailParams,
    $verificationParams: BusinessVerificationParams!
  ){
    createBusinessVerification(
      businessDetailsParams :$businessDetailsParams,
      userDetailsParams :$userDetailsParams,
      verificationParams :$verificationParams,
    ){
      id
      business {
        name
        abn
        address
        verified
      }
      currentState
      abnVerified
      nameVerified
      postcodeVerified
      identityVerified
      profileNameVerified
      photoVerified
      lastAction
      lastActionAt
      approvedBy {
        id
        name
      }
      verifiedAt
      expiredAt
      rejectionReason
      createdAt
      updatedAt
      showConfirmDetailsStep
      showIdentificationStep
      displayState
    }
  }
`;

export const DISMISS_UNVERIFIED_BANNER = gql`
  mutation dismissUnverifiedBanner {
    dismissUnverifiedBanner {
      message
    }
  }
`;
