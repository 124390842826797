import { RestLink } from 'apollo-link-rest';
import isEmpty from 'lodash/isEmpty';

import { getBuildHeaders } from 'lib/utils/Environment';

import {
  ABN_ENDPOINT,
  CONTENT_HUB_ACF_ENDPOINT,
  FIND_ADDRESS_ENDPOINT,
  GET_ADDRESS_METADATA_ENDPOINT,
  GET_COST_GUIDE_AUTHOR_ENDPOINT,
  ZENDESK_CREATE_TICKET_ENDPOINT,
  V6_ENDPOINT
} from './utils/constants';

const BUILD_HEADERS = getBuildHeaders();

export const restLink = new RestLink({
  uri: '',
  endpoints: {
    zendeskCreateTicket: ZENDESK_CREATE_TICKET_ENDPOINT,
    getCostGuideAuthor: GET_COST_GUIDE_AUTHOR_ENDPOINT,
    findAddress: FIND_ADDRESS_ENDPOINT,
    getAddressMetadata: GET_ADDRESS_METADATA_ENDPOINT,
    contentHub: CONTENT_HUB_ACF_ENDPOINT,
    getAbnData: ABN_ENDPOINT,
    v6: V6_ENDPOINT
  },
  ...(!isEmpty(BUILD_HEADERS) && { headers: BUILD_HEADERS })
});
