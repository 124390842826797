import { gql } from '@apollo/client';

export const GET_JOB_FORM_QUESTIONS = gql`
  query jobFormQuestions($categoryId: ID!, $jobFormV2: Boolean!){
    jobFormQuestions(categoryId: $categoryId, jobFormV2: $jobFormV2) {
      id
      question
      hint
      displayAs
      inputType
      rank
      allowAttachments
      placeholder
      optional
      clusterQuestion
      clusteredById
      clusterType
      rankInCluster
      answers {
        id
        answer
        rank
        nextQuestionId
        serviceTypeId
        nextUrl @skip(if: $jobFormV2)
      }
    }
  }
`;

export const GET_JOB_STATS = gql`
  query jobStats ($from: String, $serviceTypeId: Int, $state: String) {
    jobStats(from: $from, serviceTypeId: $serviceTypeId, state: $state)
  }
`;
