import { ApolloLink, Context } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { getGraphQlToken, getDomain, getBuildHeaders } from 'lib/utils/Environment';

const BUILD_HEADERS = getBuildHeaders();
const GRAPHQL_TOKEN = getGraphQlToken();
const DOMAIN = getDomain();

export const authLink = (
  context: Context
) => new ApolloLink((operation, forward) => {
  const linkHeaders = {
    X_SCOPE_TOKEN: GRAPHQL_TOKEN,
    X_SCOPE_DOMAIN: DOMAIN,
    ...(!isEmpty(BUILD_HEADERS) && BUILD_HEADERS)
  };

  // handle cookie for server side rendering
  if (context && context.req) {
    const { headers } = pick(context.req, ['headers']);

    if (headers && headers.cookie) {
      linkHeaders['cookie'] = headers.cookie;
    }
  }

  operation.setContext(({
    headers: linkHeaders,
    context,
    fetchOptions: {
      credentials: 'include'
    }
  }));

  return forward(operation);
});
