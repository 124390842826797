import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';

import { ICurrentUser } from 'types/oneflare.com.au/user';

import { getDataDogConfig } from '../utils/Environment';

class DataDogRumAgent {
  private static instance: DataDogRumAgent;
  static initialized = false;

  private constructor() {
    // only initiliaze datadogRum on the client side
    if (typeof window !== 'undefined') {
      const config = getDataDogConfig();
      if (config.applicationId && config.clientToken) {
        this.initializeDataDogRum(config);
      }
    }
  }

  private initializeDataDogRum(config: RumInitConfiguration): void {
    datadogRum.init(config);
    DataDogRumAgent.initialized = true;
  }

  public static initializeAgent() {
    if (!DataDogRumAgent.instance) {
      DataDogRumAgent.instance = new DataDogRumAgent();
    }
  }

  static setRumUser(userPayload: ICurrentUser['user']): void {
    if (!this.initialized) {
      console.warn('Datadog RUM agent not initialized');
      return;
    }

    const user = {
      id: String(userPayload.id)
    };
    datadogRum.setUser(user);
  }

 static addRumUserAction(name: string, payload: Record<string, string | boolean | object | number>): void {
    if (!this.initialized) {
      console.warn('Datadog RUM agent not initialized');
      return;
    }
    datadogRum.addAction(name, payload);
  }

  static addCustomKey(key: string, value: any): void {
    if (!this.initialized) {
      console.warn('Datadog RUM agent not initialized');
      return;
    }
    datadogRum.setGlobalContextProperty(key, value);
  }

  static removeCustomKey(key: string): void {
    if (!this.initialized) {
      console.warn('Datadog RUM agent not initialized');
      return;
    }
    datadogRum.removeGlobalContextProperty(key);
  }

  static addRumError(error: unknown, message: string, extensions = {}): void {
    if (!this.initialized) {
      console.warn('Datadog RUM agent not initialized');
      return;
    }

    const additionalAttributes = { errorSource: message, ...extensions };
    if (typeof error === 'string') {
      datadogRum.addError(new Error(error), additionalAttributes);
    } else if (error instanceof Error) {
      datadogRum.addError(error, additionalAttributes);
    } else {
      try {
        const errorAttributes = { ...error as Record<string, unknown> };
        datadogRum.addError(new Error(message, { cause: errorAttributes }), additionalAttributes);
      } catch {
        datadogRum.addError(new Error('An unknown error occurred'));
      }
    }
  }
}

export { DataDogRumAgent };
