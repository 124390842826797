import media from '@oneflare/flarekit/lib/libs/media';
import { withTheme, createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.font.primaryRegular};
    font-size: 14px;
    margin: 0;
    text-size-adjust: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  @media only percy {
    .e2e-hide {
      visibility: hidden;
    }

    #launcher {
      display: none;
    }
  }

  /* TODO: Fix flarekit Modal to handle class selectors https://airtasker.atlassian.net/browse/ONEFLARE-2614 */

  .finetune-leads-modal__overlay {
    z-index: 1000;
  }

  .finetune-leads-modal__container {
    border-radius: 8px;
  }

  .finetune-leads-modal__footer {
    padding-inline: 0;
    padding-block-start: 0;
    padding-block-end: 0;

    ${media.mdUp`
      padding-block-end: ${({ theme }) => theme.spacing.md};
    `}
  }

  :root {
    --rt-opacity: 1;
  }
`;

export default withTheme(GlobalStyles);
