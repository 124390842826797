import { ErrorResponse, onError } from '@apollo/client/link/error';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';

export const errorHandlerLink = onError((error: ErrorResponse) => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      const { message, locations, path, extensions } = error;
      const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      if (typeof window !== 'undefined') {
        DataDogRumAgent.addRumError(new Error(errorMessage), '[GraphQL error]', extensions);
      } else {
        // restrict console errors to server side
        console.error(errorMessage);
      }
    });
  }

  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
    if (typeof window !== 'undefined') {
      DataDogRumAgent.addRumError(networkError, '[Network error]');
    }
  }
});
