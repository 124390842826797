import { serverRuntimeConfig } from 'lib/utils/Environment';

const GTMNoScript = () => {
  const GTM_ID = serverRuntimeConfig.GTM_ID;
  return (
    // eslint-disable-next-line react/no-danger
    <noscript dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display:none;visibility:hidden;"></iframe>` }} />
  );
};

export { GTMNoScript };
