import { concatPagination, relayStylePagination } from '@apollo/client/utilities';
// There are some GQL types that are served without an
// ID field. Type policies allow us to program Apollo
// Cache to look for predefined fields to be used as the
// key identifier of these particular types

export const typePolicies = {
  Query: {
    fields: {
      autocompleteLocation: concatPagination(),
      businessListing: relayStylePagination({
        merge(existing = [], incoming = []) {
          return [...existing, ...incoming];
        }
      }),
      getCategoryCostGuides: concatPagination(),
      getCostGuidesForHomepage: concatPagination()
    }
  },
  CurrentUser: {
    keyFields: ['user', ['id']],
    merge: true,
    fields: {
      user: {
        merge: true
      }
    }
  },
  GetAbnData: {
    keyFields: ['Abn'],
    merge: true
  },
  CategoryLocation: {
    keyFields: ['location', ['id']],
    merge: true
  },
  ZoneHierarchy: {
    keyFields: ['stateId']
  },
  User: {
    fields: {
      business: {
        merge: true
      }
    }
  },
  CategoryRoot: {
    keyFields: ['category', ['id']],
    merge: true
  },
  CategoryDirectoryContent: {
    keyFields: ['category', ['id']],
    merge: true
  },
  Reviews: {
    keyFields: ['businessId'],
    fields: {
      reviews: {
        keyArgs: false,
        merge: (existing, incoming, { variables: { perPage = 5, page = 1 } }) => {
          const merged = existing ? existing.slice(0) : [];
          incoming.forEach((el, index) => { merged[perPage * (page - 1) + index] = el; });
          return merged;
        }
      }
    }
  },
  DynamicPageData: {
    keyFields: ['main_title'],
    merge: true
  },
  UserFlashNotification: {
    keyFields: ['message']
  },
  PaginatedMatchedBusinesses: {
    keyFields: [],
    fields: {
      businesses: {
        merge: (existing, incoming) => {
          return existing ? [...existing, ...incoming] : incoming;
        }
      }
    }
  },
  PaginatedQuotingCreditTransactions: {
    keyFields: [],
    fields: {
      keyArgs: ['keyword', 'type'],
      paginatedQuotingCreditTransactions: {
        merge: (existing, incoming) => {
          const existingSet = new Set(
            existing?.map((entry) => entry.__ref)
          );
          incoming.forEach((entry) => {
            if (!existingSet.has(entry.__ref)) {
              existingSet.add(entry.__ref);
            }
          });
          const mergedEntries = [...existingSet].map((entry) => {
            return { __ref: entry };
          });
          const mergedData = existing ? mergedEntries : incoming;

          return mergedData;
        }
      }
    }
  },
  PaginatedLeads: {
    keyFields: [],
    fields: {
      jobs: {
        merge: (existing, incoming) => {
          return existing ? [...existing, ...incoming] : incoming;
        }
      }
    }
  }
};
