import { ApolloProvider } from '@apollo/client';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { useEffect, memo } from 'react';

import { useApollo } from 'lib/apollo/withApolloClient';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import withJobFormController from 'lib/oneflare-job-form/withJobFormController';
import { GTMScript } from 'lib/scripts';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import AppWrapper from 'pages/AppWrapper';
import withReferer from 'shared/components/HighOrderComponent/withReferer';

import 'react-tooltip/dist/react-tooltip.css';
// import dynamic from 'next/dynamic';

// TODO: https://airtasker.atlassian.net/browse/ACQ-4814 
// const ClientFeatureManagerSetupWrapper = dynamic(() => import('lib/features/ClientFeatureManagerSetupWrapper').then((module) => {
//   return module.ClientFeatureManagerSetupWrapper;
// }), {
//   ssr: false
// });

// TODO: https://airtasker.atlassian.net/browse/ACQ-4814 
// const OneflareAnalyticsPageTrackerWrapper = dynamic(() => import('lib/analytics/OneflareAnalyticsPageTrackerWrapper'), {
//   ssr: false
// });

type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactElement;
  router: NextRouter;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps);
  const router = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    DataDogRumAgent.initializeAgent();
  }, []);

  return (
    <>
      <GTMScript />
      <ApolloProvider client={apolloClient}>
        {/* TODO: https://airtasker.atlassian.net/browse/ACQ-4814 */}
        {/* <OneflareAnalyticsPageTrackerWrapper /> */}
        {/* TODO: https://airtasker.atlassian.net/browse/ACQ-4814  */}
        {/* <ClientFeatureManagerSetupWrapper /> */}
        <AppWrapper>{getLayout(<Component {...pageProps} router={router} />)}</AppWrapper>
      </ApolloProvider>
      {publicRuntimeConfig.DOMAIN === 'oneflare' && (
        <style jsx global>
          {`
            @font-face {
              font-display: swap;
              font-family: 'montserratlight';
              font-style: italic;
              font-weight: normal;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-300italic.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-300italic.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratlight';
              font-style: normal;
              font-weight: normal;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-300.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-300.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratregular';
              font-style: normal;
              font-weight: normal;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-regular.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-regular.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratregular';
              font-style: italic;
              font-weight: normal;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-italic.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-italic.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratbold';
              font-style: normal;
              font-weight: bold;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-700.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-700.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratbold';
              font-style: italic;
              font-weight: bold;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-700italic.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-700italic.woff')
                  format('woff');
            }
          `}
        </style>
      )}
    </>
  );
};

export default memo(withReferer(withJobFormController(MyApp)));
