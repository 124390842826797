import { publicRuntimeConfig } from 'lib/utils/Environment';

export interface CloudFrontImageResizeOptions {
  bucket: string;
  key: string;
  edits?: {
    resize?: {
      width: number,
      height: number,
      fit: string
    },
    jpeg?: {
      quality: number
    }
  };
}

type CloudFrontImageResizeBase64 = (imageDetails: CloudFrontImageResizeOptions) => string;
export const cloudFrontImageResizeBase64: CloudFrontImageResizeBase64 = (imageDetails) => {
  const imageDetailsToString = JSON.stringify(imageDetails);

  if (typeof window === 'undefined') {
    return Buffer.from(imageDetailsToString).toString('base64');
  }

  return btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(imageDetails))));
};


type GetCloudFrontImageUrl = (imageDetails: CloudFrontImageResizeOptions) => string;
export const getCloudFrontImageUrl: GetCloudFrontImageUrl = ({ key, bucket, edits = {} }) => {
  const imageOptions = {
    bucket,
    key,
    edits
  };
  return `${process.env.CLOUDFRONT_IMAGE_RESIZE_URL ?? publicRuntimeConfig.CLOUDFRONT_IMAGE_RESIZE_URL}/${cloudFrontImageResizeBase64(imageOptions)}`;
};
