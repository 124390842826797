import { getSiteUrl, getWpContentHubApi } from 'lib/utils/Environment';

const CONTENT_HUB_URL = getWpContentHubApi();
const SITE_URL = getSiteUrl();

const CONTENT_HUB_ACF_ENDPOINT = `${CONTENT_HUB_URL}/wp-json/wp/v2`;
const V6_ENDPOINT = `${SITE_URL}/api/v6`;
const ABN_ENDPOINT = `${SITE_URL}/api/abn`;

const GET_COST_GUIDE_AUTHOR_ENDPOINT = 'https://www.oneflare.com.au/wp-json/wp/v2/users';
const FIND_ADDRESS_ENDPOINT = 'https://api.addressfinder.io/api/au/address/autocomplete';
const GET_ADDRESS_METADATA_ENDPOINT = 'https://api.addressfinder.io/api/au/address/info';

// https://github.com/Oneflare/oneflare-client/blob/6515223cbd3afd8dd89f6b82caa8e1a3a4a0ab47/pages/api/support.ts
const ZENDESK_CREATE_TICKET_ENDPOINT = '/api/support';

export {
  GET_COST_GUIDE_AUTHOR_ENDPOINT,
  ZENDESK_CREATE_TICKET_ENDPOINT,
  GET_ADDRESS_METADATA_ENDPOINT,
  CONTENT_HUB_ACF_ENDPOINT,
  FIND_ADDRESS_ENDPOINT,
  ABN_ENDPOINT,
  V6_ENDPOINT
};
