import isEmpty from 'lodash/isEmpty';
import { initializeApollo } from 'lib/apollo/initializeApolloClient';
import {
  type NormalizedCacheObject,
  type DocumentNode,
  type FetchPolicy,
  type OperationVariables,
  ApolloError,
  ApolloClient,
  MutationFetchPolicy
} from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { trace, SpanStatusCode } from '@opentelemetry/api';
import type { GraphQLError } from 'graphql';
import { APIError, ClientNetworkError, ClientUnknownError, GraphQLServerError } from 'types/oneflare.com.au/apiErrors';

/**
 * runQuery was previously used to handle server side queries
 * `lib/utils/runQuery.js`
 * as it only run a single promise without await implemented
 * this made it hard to ascertain when queries were being resolved
 * as well as making it hard to scale with other methods like
 *
 * ```
 *   readQuery | writeQuery | updateQuery
 * ```
 */
export default class ApolloRun {
  /**
   * 
   * @param {NextPageContext} ctx Next.js view context
   * @param {FetchPolicy} fetchPolicy only applies to queries and not mutations
   */
  constructor(ctx?: any, fetchPolicy?: FetchPolicy) {
    this.client = initializeApollo(ctx ?? null);
    this.fetchPolicy = fetchPolicy;
  }

  client: ApolloClient<NormalizedCacheObject>;

  private fetchPolicy: FetchPolicy;

  get apolloClient() {
    return this.client;
  }

  async query<T = any>(query: DocumentNode, variables?: OperationVariables): Promise<[T, string | ApolloError]> {
    return await trace.getTracer(
      'next.js'
    ).startActiveSpan(getOperationName(query), async (span) => {
      const recordException = (exception) => {
        span.setAttribute('graphQl.queryName', getOperationName(query));
        span.setAttribute('graphQl.errorMessage', exception.message);
        span.setStatus({ code: SpanStatusCode.ERROR });
        span.recordException(exception);
      };

      try {
        const result = await this.client.query<T>({
          query,
          ...(this.fetchPolicy && { fetchPolicy: this.fetchPolicy }),
          ...(variables && { variables })
        });
        const { data, error, errors } = result;
        switch (true) {
          case !isEmpty(errors):
            return [null, errors.map((error: GraphQLError) => {
              recordException(error);
              return error.message;
            }).join(', ')]; // return Graphql Errors
          case !isEmpty(error):
            recordException(error);
            return [null, error.message]; // return Apollo client Errors
          default:
            return [data, null];
        }
      } catch (exception) {
        recordException(exception);
        return [null, exception];
      } finally {
        span.end();
      }
    }) as [T, string | ApolloError];
  }

  async mutate<T = any, A = any>(mutation: DocumentNode, variables: A | OperationVariables, fetchPolicy = 'no-cache' as MutationFetchPolicy): Promise<[T, APIError | null]> {
    return await trace.getTracer('next.js').startActiveSpan(getOperationName(mutation), async (span) => {
      try {
        const result = await this.client.mutate<T>({
          mutation,
          variables,
          fetchPolicy
        });
        const { data, errors } = result;
        if (!isEmpty(errors)) {
          const message = errors.map(({ message, locations, path }: GraphQLError) => {
            return `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`;
          }).join('\n');

          const graphQLServerError = new GraphQLServerError(message, errors[0]?.extensions?.code);
          return [null, graphQLServerError];
        }
        return [data, null];
      } catch (exception) {
        if (exception instanceof ApolloError && exception.networkError) {
          const networkError = new ClientNetworkError(exception.networkError.message, 'NETWORK_ERROR');
          return [null, networkError];
        }

        const unknownError = new ClientUnknownError(exception.message, 'UNKNOWN_ERROR');
        return [null, unknownError];
      } finally {
        span.end();
      }
    }) as [T, APIError | null];
  }

  // TODO: add readQuery

  // TODO: add updateQuery

  // TODO: add writeQuery
}

