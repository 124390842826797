import { useRouter } from 'next/router';
import {
  ComponentType,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSsrDone } from 'shared/utils/hooks';

type WithRefererArgs = {
  getHost?: boolean;
}

type WithReferrerProps = [
  Component: ComponentType,
  args?: WithRefererArgs
];

const withReferer = <P extends Record<string, any>>(...[
  Component,
  args = {
    getHost: false
  }
]: WithReferrerProps) => {
  const RefererHandler = (props: P) => {
    // rely on client side for referer
    const router = useRouter();
    const isSsrDone = useSsrDone();
    const [isIframe, setIsIframe] = useState(false);

    useEffect(() => {
      const iframe = typeof window !== 'undefined' && window.self !== window.top;
      setIsIframe(iframe);
    }, []);

    const originalReferer = useMemo(() => {
      if (!isSsrDone) return '';
      const {
        getHost
      } = args;
      const referrer = window?.document.referrer;
      const host = `${window?.location.host}${router.asPath.replace(/\?.*/, '')}`;
      const origin = window?.location.origin;
      if (getHost) return host;
      return referrer || origin;
    }, [isSsrDone, router.asPath]);

    const propsWithReferer = useMemo(
      () => ({
        ...props,
        router,
        isIframe,
        originalReferer
      }),
      [originalReferer, props, router, isIframe]
    );

    return <Component {...(propsWithReferer as P)} />;
  };

  return RefererHandler;
};

export default withReferer;
