export interface APIError {
  message: string;
  readonly type: 'Network' | 'Unknown' | 'GraphQL';
  code?: string;
}

// type-guard
export const isAPIError = (error: any): error is APIError => {
  if (!error) return false;
  return error && typeof error.message === 'string' &&
         ['Network', 'Unknown', 'GraphQL'].includes(error.type);
};

class ClientNetworkError extends Error implements APIError {
  readonly type = 'Network';
  code?: string;

  constructor(message: string = 'Network error occurred', code?: string) {
    super(message);
    this.code = code;    
  }
}

class ClientUnknownError extends Error implements APIError {
  readonly type = 'Unknown';
  code?: string;
  
  constructor(message: string = 'Unknown error found', code?: string) {
    super(message);
    this.code = code;    
  }
}

class GraphQLServerError extends Error implements APIError {
  readonly type = 'GraphQL';
  code?: string;

  constructor(message: string = 'GraphQL server error', code?: string) {
    super(message);
    this.code = code;    
  }
}

export { ClientNetworkError, ClientUnknownError, GraphQLServerError };