import AlertContainer from '@oneflare/flarekit/lib/components/AlertContainer';
import { createContext, MutableRefObject } from 'react';

import type { CategoryModel, DynamicPageDataModel, LocationModel } from 'types/shared';

interface PageContextModel {
  category?: CategoryModel | null;
  location?: LocationModel | null;
  params?: Record<string, string | string[]> | null;
  AlertContainer: MutableRefObject<AlertContainer> | null;
  dynamicPageData?: DynamicPageDataModel | null;
}

const PageContext = createContext<PageContextModel>({
  AlertContainer: null,
  category: null,
  location: null,
  dynamicPageData: null,
  params: null
});

export { PageContext, type PageContextModel };
