import type { UserAgent } from 'next-useragent';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  useMemo
} from 'react';
import scrollToComponent from 'react-scroll-to-component-ssr';
import { type DefaultTheme, ThemeContext } from 'styled-components';

import { PageContext, type PageContextModel } from '@context-providers';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { useThemeContext } from 'shared/components/HighOrderComponent/withThemeProvider/withThemeProvider';
import { type CloudFrontImageResizeOptions, getCloudFrontImageUrl } from 'shared/utils/cloudFrontImageResize';
import { findAnchorIn } from 'shared/utils/helpers';
import { ThemeContextModel } from 'types/oneflare.com.au/themeContext';

// Returns current window size
export const useWindowResize = (defaultSize: number) => {
  const [viewportWidth, setViewportWidth] = useState(defaultSize);
  const updateWindowDimensions = useCallback(() => {
    setViewportWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    updateWindowDimensions();
    return () => { window.removeEventListener('resize', updateWindowDimensions); };
  }, [updateWindowDimensions]);
  return viewportWidth;
};

// Checks window size is greater than the provided breakpoint, returns boolean
export const useBreakpoint = (
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  defaultSize = (typeof window !== 'undefined') ? window.innerWidth : 0
) => {
  const viewportWidth = useWindowResize(defaultSize);
  const theme = useThemeContext();
  const breakpointValue = parseInt(theme.breakpoint[breakpoint].min, 10);
  const [isBreakpoint, setIsBreakpoint] = useState(viewportWidth >= breakpointValue);
  useEffect(() => {
    setIsBreakpoint(viewportWidth >= breakpointValue);
  }, [breakpoint, breakpointValue, viewportWidth]);
  return isBreakpoint;
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(null);
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return savedCallback.current;
  }, [delay]);
};

export const useClock = () => {
  const [clock, setClock] = useState<Date>(new Date());
  useInterval(() => {
    setClock(new Date());
  }, 1000);
  return clock;
};

export const useGenerateAvatarBackgroundColor = (word: string): string | boolean => {
  const themeContext = useContext(ThemeContext);
  const theme = themeContext as ThemeContextModel & DefaultTheme;
  const bgColorPalette = [
    theme.color.info,
    theme.color.secondaryLight,
    theme.color.tertiaryDark,
    theme.color.success,
    theme.color.danger
  ];

  if (!word) return false;

  // Convert string a = 1, b = 2 etc...
  // return sum of chars. Example 'ab' --> 3
  // Add all Char number together, any NaN values default color to 0
  const sumCharNumbers = [...word]
    .reduce((a, b) => a + b.toLowerCase().charCodeAt(0) - 96, 0) || 0;
  return bgColorPalette[sumCharNumbers % bgColorPalette.length];
};

export const useHandleMutationAlert = () => {
  const { AlertContainer } = useContext<PageContextModel>(PageContext);
  const handleAlert = useCallback(({ errors, datadogRumMsg, successMsg }: {
    errors?: any;
    datadogRumMsg?: string;
    successMsg?: string;
  }) => {
    if (errors) {
      const errorMsg = typeof errors === 'string' ? errors : errors?.[0]?.message;
      AlertContainer.current?.error({ message: errorMsg || 'Something went wrong, please try again'});
      DataDogRumAgent.addRumError(errors, datadogRumMsg);
      return;
    }
    if (successMsg) {
      AlertContainer?.current?.success({
        message: successMsg
      });
    }
  }, [AlertContainer]);
  return [handleAlert];
};

type GetCloudFrontImageUrl = (options: {
  key: string; bucket?: string; dynamicEdits?: CloudFrontImageResizeOptions['edits'];
}) => string
type UseCloudFrontImageResizeURL = (options: {
  defaultBucket: string; defaultEdits?: CloudFrontImageResizeOptions['edits'];
}) => [GetCloudFrontImageUrl];

export const useCloudFrontImageResizeURL: UseCloudFrontImageResizeURL = ({ defaultBucket, defaultEdits = {}}) => {
  const getCloudFrontImage = useCallback(({ key, bucket = defaultBucket, dynamicEdits = {}}) => (
      getCloudFrontImageUrl({
        key,
        bucket,
        edits: { ...defaultEdits, ...dynamicEdits }
      })),
    [defaultBucket, defaultEdits]
  );
  return [getCloudFrontImage];
};

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(null);

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      const gotValue = item ? JSON.parse(item) : initialValue;

      setStoredValue(gotValue);
    } catch (error) {
      const errorMsg = error?.message;
      DataDogRumAgent.addRumError(JSON.stringify(error), errorMsg);
      setStoredValue(initialValue);
    }
  }, [key, initialValue]);

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        const errorMsg = error?.message;
        DataDogRumAgent.addRumError(JSON.stringify(error), errorMsg);
      }
    },
    [key, storedValue]
  );

  return [storedValue, setValue] as const;
};

export const useIsMounted = () => {
  const ref = useRef(true);
  useEffect(() => {
    ref.current = false;
  }, []);
  return ref.current;
};

export const useSsrDone = () => {
  const [ssrDone, setSsrDone] = useState(false);
  useEffect(() => {
    setSsrDone(true);
  }, []);

  return ssrDone;
};

// custom hook to simulate prevProps for componentDidUpdate
export const usePrevious = (value: any) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// detect bot clientSide
export const useBotDetection = (ua: UserAgent): {
  isBot: boolean;
  staticRender: boolean;
} => {
  const isBot = useMemo(() => ua?.isBot ?? false, [ua?.isBot]);
  const staticRender = useMemo(() => {
    const source = ua?.source;
    const botsWeCareAbout = ['google', 'bingbot', 'screaming frog seo'];
    for (let key = 0; key < botsWeCareAbout.length; key += 1) {
      if (
        typeof source !== 'undefined'
        && source.toLowerCase().indexOf(botsWeCareAbout[key]) > -1
      ) {
        return true;
      }
    }
    return false;
  }, [ua?.source]);

  return { isBot, staticRender };
};

export const useScrollToRefComponent = (componentRef) => {
  const scrollToRef = useCallback((refName) => scrollToComponent(componentRef.current[refName], {
    offset: -90, align: 'top', duration: 500, ease: 'inOutCirc'
  }), [componentRef]);

  useEffect(() => {
    if (componentRef.current) {
      scrollToRef(findAnchorIn(window.location.href));
    }
  }, [componentRef, scrollToRef]);

  return scrollToRef;
};
