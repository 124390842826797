import { gql } from '@apollo/client';

// #region pending job operations
export const CREATE_PENDING_JOB = gql`
  mutation createPendingJob($attributes: CreatePendingJob!) {
    createPendingJob(attributes: $attributes) {
      pendingJobUuid
    }
  }
`;

export type CreatePendingJobResponse = {
  createPendingJob: {
    pendingJobUuid: string;
  };
};

export const UPDATE_PENDING_JOB = gql`
  mutation updatePendingJob($attributes: UpdatePendingJob!) {
    updatePendingJob(attributes: $attributes) {
      pendingJobUuid
      mobileVerificationRequired
    }
  }
`;

export type UpdatePendingJobResponse = {
  updatePendingJob: {
    pendingJobUuid: string;
    MobileVerificationRequired?: boolean;
  };
};

// #endregion pending job operations

export const CREATE_JOB = gql`
  mutation createJob($attributes: CreateJob!) {
    createJob(attributes: $attributes) {
      id
      redirectUrl,
      stats {
        avgJobCost
        leadsSent
        maxJobCost
        minJobCost
        timeToFirstQuote
      }
    }
  }
`;

export type CreateJobReponse = {
  createJob: {
    id: number;
    redirectUrl: string;
    stats?: {
      avgJobCost: number;
      leadsSent: number;
      maxJobCost: number;
      minJobCost: number;
      timeToFirstQuote: number;
    };
  }
};

export const VERIFY_MOBILE_AUTHENTICATION_TOKEN = gql`
  mutation verifyMobile($email: String, $token: String!) {
    verifyMobile(email: $email, token: $token) {
      failedAttempts
      message
      nextRetryAt
      status
    }
  }
`;

export type VerifyMobileResponse = {
  verifyMobile: {
    failedAttempts: number;
    message: string;
    nextRetryAt: string;
    status: string;
  };
};

export const SEND_EMAIL_MAGIC_LINK = gql`
  mutation sendNotificationPendingJob($dclid: String, $fbclid: String, $gaId: String, $gclid: String, $msclkid: String, $pendingJobUuid: String, $pendingJobId: Int, $spId: String) {
    sendNotificationPendingJob(dclid: $dclid, fbclid: $fbclid, gaId: $gaId, gclid: $gclid, msclkid: $msclkid, pendingJobUuid: $pendingJobUuid, pendingJobId: $pendingJobId, spId: $spId) {
      pendingJobUuid
    }
  }
`;

export type SendNotificationPendingJobResponse = {
  sendNotificationPendingJob: {
    pendingJobId?: number;
    pendingJobUuid?: string;
  };
}

export const CREATE_USER_SESSIONS = gql`
  mutation createUserSessions($userSession: AuthProviderCredentialsInput!) {
    createUserSessions(userSession: $userSession) {
      user {
        id
      }
    }
  }
`;

export type CreateUserSessionsResponse = {
  createUserSessions: {
    user: {
      id: number;
    };
  };
}

export const RESET_PASSWORD = gql`
  mutation sendPasswordReset($email: String) {
    sendPasswordReset(email: $email) {
      success
    }
  }
`;

export type SendPasswordResetResponse = {
  sendPasswordReset: {
    success: boolean;
  };
}