import { gql } from '@apollo/client';

export const SEND_SMS = gql`
  mutation sendMobileAuthenticationToken($email: String!) {
    sendMobileAuthenticationToken(
      email: $email,
    ) {
      status
      failedAttempts
      nextResendAvailableAt
      displayNumber
      nextRetryAt
      message
    }
  }
`;

export type SendMobileAuthenticationTokenResponse = {
  sendMobileAuthenticationToken: {
    displayNumber: string;
    failedAttempts: number;
    message: string;
    nextResendAvailableAt: string;
    nextRetryAt: string;
    status: string;
  }
};

export const CHECK_CODE = gql`
  mutation VerifyPendingEnquiryConversationMobileAuthenticationToken($email: String!, $token: String!) {
    verifyPendingEnquiryConversationMobileAuthenticationToken(
      email: $email,
      token: $token,
    ) {
      failedAttempts
      message
      nextRetryAt
      redirectUrl
      status
      quoteId
      jobId
    }
  }
`;
