import { gql } from '@apollo/client';

export const CREATE_USER_WITH_PENDING_JOB = gql`
  mutation createUserWithPendingJob(
    $id: ID
    $name: String!
    $phone: String!
    $pendingJobUuid: String
    $marketingConsentConfirmed: Boolean
  ) {
    createUserWithPendingJob(
      id: $id
      name: $name
      phone: $phone
      pendingJobUuid: $pendingJobUuid
      marketingConsentConfirmed: $marketingConsentConfirmed
    ) {
      jobId
      redirectUrl
      mobileVerificationRequired
    }
  }
`;


export interface CreateUserWithPendingResponse {
  createUserWithPendingJob: {
    redirectUrl: string;
    jobId: number;
    mobileVerificationRequired: boolean;
  };
}
